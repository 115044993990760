import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";
import { graphql } from "gatsby";

export default function LateNightTalking({ data }) {
  return (
    <Layout>
      <Helmet title="Late Night Talking | Harry Styles" />

      <h1>Late Night Talking</h1>

      <h2>Harry Styles</h2>

      <h4>Key: F#m</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Img fluid={data.file.childImageSharp.fluid} />

      <Columns columns="2">
        <Verse>
          <p>Things haven't been quite the same</p>
          <p>There's a haze on the horizon, babe</p>
          <p>It's only been a couple of days</p>
          <p>And I miss you, mm, yeah</p>
        </Verse>
        <Verse>
          <p>When nothin' really goes to plan</p>
          <p>You stub your toe or break your camera</p>
          <p>I'll do everythin' I can</p>
          <p>To help you through</p>
        </Verse>
        <PreChorus>
          <p>If you're feelin' down</p>
          <p>I just wanna make you happier, baby</p>
          <p>Wish I was around</p>
          <p>I just wanna make you happier, baby</p>
        </PreChorus>
        <Chorus>
          <p>We've been doin' all this late night talkin'</p>
          <p>'Bout anythin' you want until the mornin'</p>
          <p>Now you're in my life</p>
          <p>I can't get you off my mind</p>
        </Chorus>
        <Verse>
          <p>I've never been a fan of change</p>
          <p>But I'd follow you to any place</p>
          <p>If it's Hollywood or Bishopsgate</p>
          <p>I'm coming too</p>
        </Verse>
        <PreChorus>
          <p>If you're feelin' down</p>
          <p>I just wanna make you happier, baby</p>
          <p>Wish I was around</p>
          <p>I just wanna make you happier, baby</p>
        </PreChorus>
        <Chorus>
          <p>We've been doin' all this late night talkin'</p>
          <p>'Bout anythin' you want until the mornin'</p>
          <p>Now you're in my life</p>
          <p>I can't get you off my mind</p>
        </Chorus>
        <Bridge>
          <p>Can't get you off my mind</p>
          <p>Can't get you off my mind (can't get you off my mind)</p>
          <p>I won't even try (I won't even try)</p>
          <p>To get you off my mind (get you off my mind)</p>
        </Bridge>
        <Chorus>
          <p>We've been doin' all this late night talkin'</p>
          <p>'Bout anythin' you want until the mornin'</p>
          <p>Now you're in my life</p>
          <p>I can't get you off my mind</p>
        </Chorus>
        <Bridge>
          <p>Can't get you off my mind (all this late night talkin')</p>
          <p>Can't get you off my mind (all this late night talkin')</p>
          <p>I won't even try (all this late night talkin')</p>
          <p>Can't get you off my</p>
          <p>All this late night talkin'</p>
        </Bridge>
      </Columns>
    </Layout>
  );
}

export const query = graphql`
  query {
    file(relativePath: { eq: "late-night-talking.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;